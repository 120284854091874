import React, { useState, useRef, useEffect } from "react";
import QrReader from "react-qr-scanner";
import { userCheckinService } from "../../../Services/userDetailService";
import "./scanner.css";
import { toast } from "react-toastify";
import lottie from "lottie-web";
import checkLogo from "../../../Assets/checked-icon.svg";

function Scan() {
  const [result, setResult] = useState("");
  const [loader, setLoader] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const container = useRef(null);
  const container1 = useRef(null);
  const container2 = useRef(null);
  const scannerRef = useRef(null);

  const handleScan = async (data) => {
    if (data?.text) {
      setLoader(true);
      const result = await userCheckinService(data.text);

      setLoader(false);
      setResult(result);

      if (result.text === "0" && result.data) {
        setUserDetails(result.data);
      }

      setTimeout(() => {
        setResult("");
        setUserDetails(null);
      }, [6000]);
    }
  };

  const handleError = (err) => {
    toast(err);
  };

  useEffect(() => {
    const loadAnimationData = async () => {
      const animationDataModule = await import(
        "../../../Assets/Animation - 1709728035204.json"
      );
      lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationDataModule.default,
      });
    };

    const loadAnimationData1 = async () => {
      const animationDataModule = await import(
        "../../../Assets/Animation - 1709729595357.json"
      );
      lottie.loadAnimation({
        container: container1.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationDataModule.default,
      });
    };

    const loadAnimationData2 = async () => {
      const animationDataModule = await import(
        "../../../Assets/Animation - 1709729843449.json"
      );
      lottie.loadAnimation({
        container: container2.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationDataModule.default,
      });
    };

    if (result.text === "0" && userDetails) {
      setTimeout(() => {
        loadAnimationData1();
        loadAnimationData2();
      }, 5000);
    } else if (result.text === "1") {
      loadAnimationData();
    }
  }, [result, userDetails]);

  if (loader) {
    return (
      <div className="main_container" style={{ alignItems: "center" }}>
        <div className="scanner_box" style={{ height: "31vh", color: "white" }}>
          <h3>Extracting user detail. Please wait</h3>
        </div>
      </div>
    );
  }

  const handleFullscreen = () => {
    setFullScreen(true);
    if (scannerRef.current) {
      if (scannerRef.current.requestFullscreen) {
        scannerRef.current.requestFullscreen();
      } else if (scannerRef.current.mozRequestFullScreen) {
        scannerRef.current.mozRequestFullScreen();
      } else if (scannerRef.current.webkitRequestFullscreen) {
        scannerRef.current.webkitRequestFullscreen();
      } else if (scannerRef.current.msRequestFullscreen) {
        scannerRef.current.msRequestFullscreen();
      }
    }

    const isFullscreenEnabled =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement;

    if (!isFullscreenEnabled) {
      setFullScreen(false);
    }
  };

  return (
    <div ref={scannerRef}>
      {!fullScreen && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            background: "black",
            left: "0",
            top: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button onClick={handleFullscreen}>Full Screen</button>
        </div>
      )}
      {fullScreen && (
        <>
          <div
            className="main_container"
            style={{ justifyContent: "center", maxWidth: "65vw" }}
          >
            <div className="scanner_box ">
              {result !== "" ? (
                result.text === "0" && userDetails ? (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                      background: "white",
                      width: "85%",
                      height: "70%",
                      borderRadius: "15px",
                    }}
                  >
                    <div style={{ height: "30%" }}>
                      <img
                        alt="check"
                        src={checkLogo}
                        style={{ marginTop: "20%", height: "50%" }}
                      />
                      <div
                        style={{
                          fontSize: "20px",
                          fontFamily: "riona sans",
                          fontWeight: "700",
                          marginTop: "10px",
                        }}
                      >
                        CHECKED IN
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "20px",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        fontFamily: "riona sans",
                      }}
                    >
                      <div style={{ marginBottom: "20px" }}>
                        <span
                          style={{
                            color: "#0294D9",
                            fontSize: "18px",
                            display: "block",
                          }}
                        >
                          Name:
                        </span>
                        <div style={{ fontSize: "23px", marginTop: "5px" }}>
                          {userDetails.name ? userDetails.name : "N/A"}
                        </div>
                      </div>
                      <div style={{ marginBottom: "20px" }}>
                        <span
                          style={{
                            color: "#0294D9",
                            fontSize: "18px",
                            display: "block",
                          }}
                        >
                          Email:
                        </span>
                        <div style={{ fontSize: "23px", marginTop: "5px" }}>
                          {userDetails.email ? userDetails.email : "N/A"}
                        </div>
                      </div>
                      <div style={{ marginBottom: "20px" }}>
                        <span
                          style={{
                            color: "#0294D9",
                            fontSize: "18px",
                            display: "block",
                          }}
                        >
                          Phone:
                        </span>
                        <div style={{ fontSize: "23px", marginTop: "5px" }}>
                          {userDetails.phone ? userDetails.phone : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "30vh",
                      textAlign: "center",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    <div ref={container} style={{ height: "100%" }}></div>
                    <h3>
                      You have already checked in <br></br>for the event
                    </h3>
                  </div>
                )
              ) : (
                <div style={{ height: "59vh", textAlign: "center" }}>
                  <h3 className="heading">
                    Place the QR Code
                    <br></br>
                    within this box to scan
                  </h3>
                  <QrReader
                    onError={handleError}
                    onScan={handleScan}
                    style={{ margin: "10px", width: "91%" }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Scan;
