import React, { useEffect } from "react";
import "./Thankyou.css";
import { useNavigate } from "react-router-dom";
function Thankyou() {
  const navigate = useNavigate();


  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, [navigate]);
  return (
    <div className="register_main_container">
      <div className="registeration_box">
        {/* Qr */}
        {/* <div style={{ width: "250px" }}>
          <img
            src={`data:image/png;base64,` + registrationResult?.watchable_url}
            alt="qrcode"
            style={{ width: "100%" }}
          />
        </div> */}
        <div className="heading2">
          Thank you for participating <br></br>This QR code has been sent to
          your registered email address.
        </div>
        {/* <button className="btn" onClick={handleClick}>
          Download
        </button> */}
      </div>
    </div>
  );
}

export default Thankyou;
