import axios from "axios";
import { BASE_API } from "../GlobalVariable";

// #region RegistrationService
export const userCheckinService = async (data) => {
  try {
    // Url
    const url = `${BASE_API}/hero/check-in/${data}`;

    // Config
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const result = await axios.get(url, config);

    console.log(result?.data?.result);
    if (result?.data?.result === "User already checked in") {
      // return true
      return { text: "1", data: null };
    }
    // return false
    return Promise.resolve({ text: "0", data: result?.data?.result });
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion

// #region Get User Data
export const registerUser = async (data) => {
  try {
    // Url
    const url = `${BASE_API}/hero/register-user`;

    // Config
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer token`,
      },
    };

    const formdata = {
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      are_you_coming: data?.checked,
      companyName: data?.companyName,
      password: "hi",
    };

    const result = await axios.post(url, formdata, config);

    return Promise.resolve(result?.data?.result);
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion
