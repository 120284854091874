// Package Imports
import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// Component Imports

// Route Imports

import HomeRoute from "./Routes/HomeRoute";
import Navbar from "./Components/Layout/Navbar";

const App = () => {
  return (
    <div>
      <Router>
        <Fragment>
          <Navbar />
          <ToastContainer />

          <HomeRoute />
        </Fragment>
      </Router>
    </div>
  );
};

export default App;
